export const serviceBreakdownContainer = `
    container
    flex
    flex-col
    lg:flex-row
    mx-auto
    my-10
`;

export const heading = `
    text-3xl
    font-space
    font-bold
    mb-5
    text-center
    lg:text-left
`;

export const pointCircle = `
    w-5
    h-5
    mr-3
`;

export const pointHeading = `
    
    font-bold
    text-md
    font-space
    text-gray-600
    mb-2
    -mt-1
    w-full
`;

export const pointDescription = `
    text-md
    font-space
    font-light
`;
