import React from 'react';
import { button, heading, heroSection, subheading } from '../styles/hero-section.styles';

const HeroSection = () => {
  return (
    <section className={heroSection} id="hero">
      <div className="container mx-auto w-full h-full flex flex-col items-center justify-center">
        <h1 className={heading}>Marketplace Insights Made Simple</h1>
        <h2 className={subheading}>
          Top performing brands use ‘Pulse’ to extract key trends & insights to make smart decisions
          to increase market share & revenue.
        </h2>
        <div className="mt-10">
          <a href="#solutions" className={button}>
            View Solutions
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
