export const heroSection = `
    w-full
    mx-auto
    h-80vh
    bg-red-400
`;

export const heading = `
    w-full
    md:w-600
    text-center
    text-2xl
    lg:text-4xl
    font-space
    font-bold
    text-pulse-black
`;

export const subheading = `
    w-full
    md:w-600
    text-2xl
    mt-5
    font-space
    font-bold
    text-white
    text-center
`;

export const button = `
    px-6
    py-3
    rounded-sm
    font-space
    font-bold
    text-white
    bg-slate-800
    text-lg
    ease-in-out 
    duration-300
    hover:bg-slate-500
`;
