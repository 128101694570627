export const routes = [
  {
    label: 'Solutions',
    path: '#solutions',
  },
  {
    label: 'How It Works',
    path: '#howItWorks',
  },
  {
    label: 'Plans',
    path: '#pricing',
  },
];
