export const productBreakdownPoints = [
  'Track your entire product catalog across multiple major marketplaces worldwide*.',
  'Optimize your digital shelf presence.',
];

export const stockBreakdownPoints = [
  'Predict and comprehend ‘Out of Stock’ situations, understand supply chain bottlenecks.',
  'Get actionable insights on what is ‘Out of Stock’ and for how long.',
  'Reduce stock replenishment time.',
];
export const competitorBreakdownPoints = [
  'Get detailed insights on what your competitors are doing. Track & monitor competitor prices and stock situations.',
  'Track your competitors products and features.',
  'Understand your market share in product segments & categories.',
  'Compare your prices to your competitors.',
];
export const complianceBreakdownPoints = [
  'Maintain SEO compliance through content monitoring, track and improve in-store SEO.',
  'Optimize your content integrity & listings',
  'Monitor price compliance',
  'Track reviews & ratings ',
];
export const campaignBreakdownPoints = [
  'Do campaign autopsies, correlate your sales data to campaigns and stock.',
  'Bespoke design services for marketplace banners  & social media campaigns.',
];
export const alertsBreakdownPoints = [
  'Setup automated alerts for out of stock situations',
  'Customized alerts based on your insights',
];
