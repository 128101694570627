import React, { useCallback, useEffect } from 'react';
import { routes } from '../consts/navbarRoutes';
import {
  brand,
  callToAction,
  mobileNav,
  nav,
  navContainer,
  navLink,
  navLinkMobile,
  navLinksContainer,
  navLinksContainerMobile,
} from '../styles/navbar.styles';
import MenuIco from '../assets/menu.svg';
import { useState } from 'react';
import CloseIco from '../assets/close.svg';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [y, setY] = useState(1);

  const handleNavigation = useCallback(
    (e) => {
      const nav = document.querySelector('#navigation');

      const window = e.currentTarget;
      if (y < window.scrollY) {
        nav.classList.add('shadow-lg');
      } else if (window.scrollY < 50) {
        nav.classList.remove('shadow-lg');
      }
      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <nav className={nav} id="navigation">
      <div className={navContainer}>
        <a className={brand} href="#hero">
          Pulse.
        </a>
        {/* DESKTOP NAV */}
        <div className={navLinksContainer}>
          {routes.map((item) => (
            <a href={item.path} key={item.path} className={navLink}>
              {item.label}
            </a>
          ))}
          <a href="#contact" className={callToAction + 'hidden lg:block'}>
            Contact Sales
          </a>
        </div>
        <div className="flex items-center lg:hidden">
          <div className="block lg:hidden ml-5" onClick={() => setShowMenu(!showMenu)}>
            <img src={MenuIco} />
          </div>
        </div>
      </div>
      {/* MOBILE NAV */}
      <motion.div
        className={mobileNav(showMenu)}
        animate={{
          x: showMenu ? 0 : '100%',
        }}
        transition={{ type: 'tween' }}>
        <div className="flex flex-row container mx-auto justify-between h-20 items-center">
          <a className={brand} href="#hero" onClick={() => setShowMenu(false)}>
            Pulse.
          </a>
          <div onClick={() => setShowMenu(false)}>
            <img src={CloseIco} />
          </div>
        </div>
        <div className={navLinksContainerMobile}>
          {routes.map((item) => (
            <a
              href={item.path}
              key={item.path}
              className={navLinkMobile}
              onClick={() => setShowMenu(false)}>
              {item.label}
            </a>
          ))}
        </div>
        <a href="#contact" className={callToAction}>
          Contact Sales
        </a>
      </motion.div>
    </nav>
  );
};

export default Navbar;
