import * as yup from 'yup';

export const contactFormSchema = yup.object().shape({
  from_name: yup.string().email('Not a valid email').required('Must provide a email address'),
  message: yup.string().required('Can you please tell us how we can help?'),
  name: yup
    .string()
    .min(6, 'must be 6 character long')
    .max(40)
    .required('Must enter your full name'),
  phone: yup.string().max(40).required('Must enter your phone number'),
  companyName: yup
    .string()
    .min(6, 'must be 6 character long')
    .max(40)
    .required('Must provide your company name'),
});
