export const nav = `
    w-full
    h-20
    flex
    flex-row
    items-center
    justify-center
    bg-white
    fixed
    top-0
    z-20
`;

export const navContainer = `
    container
    flex
    flex-row
    justify-between
    items-center
    h-full
`;

export const brand = `
    text-2xl 
    font-extrabold 
    font-space 
    text-pulse-pink
    hover:text-red-500
`;

export const callToAction = `
    px-5
    py-2
    rounded-sm
    font-space
    font-bold
    text-white
    bg-pulse-pink
    ease-in-out 
    duration-300
    hover:bg-red-500
`;

export const navLinksContainer = `
    hidden
    lg:flex
    flex-row
    items-center
    justify-center
`;

export const navLinksContainerMobile = `
    flex
    flex-col
    items-center
    justify-center
`;
export const navLink = `
    font-space 
    font-bold
    text-md
    hover:text-red-500
    ease-in-out
    duration-300
    mx-3
`;

export const navLinkMobile = `
    font-space 
    font-bold
    text-xl
    hover:text-red-500
    ease-in-out
    duration-300
    mb-10
`;

// ${show ? 'flex' : 'hidden'}
export const mobileNav = () => `
    flex
    lg:hidden
    flex-col
    items-center
    justify-start
    w-screen
    h-screen
    bg-white
    absolute
    top-0
`;
