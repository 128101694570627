import React from 'react';
import {
  sectionContainer,
  serviceContainer,
  serviceHeader,
  serviceImgContainer,
  serviceItem,
} from '../styles/service-section.styles';
import Stock from '../assets/stock.png';
import Product from '../assets/product.png';
import Complience from '../assets/complience.png';
import Competitor from '../assets/competitor.png';
import Campaign from '../assets/campaign.png';
import Alerts from '../assets/alert.png';
import { useRef } from 'react';
import { useEffect } from 'react';

const ServiceSection = ({ setHeight }) => {
  const serviceSectionRef = useRef();

  const services = [
    {
      name: 'Product Tracking',
      imgPath: Product,
    },
    {
      name: 'Stock Tracking',
      imgPath: Stock,
    },
    {
      name: 'Competitor Analysis',
      imgPath: Competitor,
    },
    {
      name: 'Compliance Tracking',
      imgPath: Complience,
    },
    {
      name: 'Campaign & Design',
      imgPath: Campaign,
    },
    {
      name: 'Automated Alerts',
      imgPath: Alerts,
    },
  ];

  useEffect(() => setHeight(serviceSectionRef.current.clientHeight), [window.innerWidth]);
  return (
    <section className={sectionContainer} id="solutions">
      <div className={serviceContainer} ref={serviceSectionRef}>
        {services.map((service) => (
          <div key={service.name} className={serviceItem}>
            <div className={serviceImgContainer}>
              <img src={service.imgPath} alt={service.name} />
            </div>
            <h3 className={serviceHeader}>{service.name}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceSection;
