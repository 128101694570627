export const section = `
    w-full
    h-auto
    lg:h-60vh
`;

export const wrapper = `
    container
    h-full
    p-3
    border-box
    mx-auto

    flex
    flex-col
    items-center
    justify-center

    bg-pulse-blue
    rounded-md
`;

export const heading = `
    text-2xl
    lg:text-4xl
    font-bold
    font-space
    mb-5
`;

export const steps_wrapper = `
    grid
    grid-cols-1
    md:grid-cols-2
    lg:grid-cols-3

   gap-3

   w-full
   mt-5
   lg:mt-10
`;

export const step_item = `
    flex
    flex-col
    items-center
    justify-start
    mb-10
    lg:mb-0
    md:mb-0
`;

export const step_header = `
    text-center
    w-full
    lg:w-72
    text-xl
    font-space
    font-bold
    text-balck
`;

export const step_count = `
    w-10
    h-10

    rounded-md
    mb-5

    flex
    items-center
    justify-center

    text-lg
    font-bold
    font-space
    text-black

    bg-pulse-yellow
`;

export const step_description = `
    text-center
    w-full
    lg:w-72
    text-md
    font-space
    font-medium
    text-white
    mt-5
`;
