import React from 'react';
import {
  heading,
  pointCircle,
  pointHeading,
  serviceBreakdownContainer,
} from '../styles/service-breakdown.styles';
import Star from '../assets/star-fill.svg';

const ServiceBreakDown = ({ title, points, onRight, img }) => {
  if (onRight) {
    return (
      <div className={serviceBreakdownContainer}>
        <div className="flex-1 flex flex-row justify-center lg:justify-end order-2 lg:order-1">
          <img src={img} alt={title} style={{ height: 300 }} className=" mr-0 lg:mr-10" />
        </div>
        <div className="flex-1 p-2 box-border order-1 lg:order-2">
          <h2 className={heading}>{title}</h2>
          {points.map((point) => (
            <div key={point}>
              <div className="flex items-start">
                <div className={pointCircle}>
                  <img src={Star} alt="" />
                </div>
                <h3 className={pointHeading}>{point}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={serviceBreakdownContainer}>
        <div className="flex-1 p-2 box-border">
          <h2 className={heading}>{title}</h2>
          {points.map((point) => (
            <div key={point}>
              <div className="flex items-start">
                <div className={pointCircle}>
                  <img src={Star} alt="" />
                </div>
                <h3 className={pointHeading}>{point}</h3>
              </div>
            </div>
          ))}
        </div>
        <div className="flex-1 flex flex-row justify-center lg:justify-start">
          <img src={img} alt={title} style={{ height: 300 }} className=" ml-0 lg:ml-10" />
        </div>
      </div>
    );
  }
};

export default ServiceBreakDown;
