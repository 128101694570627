import React from 'react';
import {
  description,
  heading,
  price_description,
  price_table_item,
  price_table_wrapper,
  price_title,
  section,
  wrapper,
} from '../styles/pricing.styles';
import TickWhite from '../assets/check-white.svg';
import TickBlack from '../assets/check-black.svg';

const PricingSection = () => {
  return (
    <section className={section} id="pricing">
      <div className={wrapper}>
        <h2 className={heading}>Convinient Plans</h2>
        <p className={description}>Choose the right plan for you</p>
        <div className={price_table_wrapper}>
          <div className={price_table_item + 'order-2 lg:order-1 bg-white'}>
            <h2 className={price_title + 'text-slate-700'}>Basic</h2>
            <p className={price_description}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium, sint!
            </p>
            <div className="flex">
              <img className="mr-2" src={TickBlack} alt="Lorem ipsum dolor sit amet." />
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
          <div
            className={
              price_table_item +
              'order-1 lg:order-2 bg-pulse-orange mt-0 mb-0 lg:-mt-5 lg:-mb-2 shadow-lg'
            }>
            <h2 className={price_title + 'text-white'}>Premium</h2>
            <p className={price_description}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium, sint!
            </p>
            <div className="flex">
              <img className="mr-2" src={TickWhite} alt="Lorem ipsum dolor sit amet." />
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
          <div className={price_table_item + 'order-3 bg-white'}>
            <h2 className={price_title + 'text-slate-700'}>Custom</h2>
            <p className={price_description}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusantium, sint!
            </p>
            <div className="flex">
              <img className="mr-2" src={TickBlack} alt="Lorem ipsum dolor sit amet." />
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
