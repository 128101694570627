export const sectionContainer = `
    container
    box-border  
    rounded-lg
    mx-auto
    relative
`;

export const serviceContainer = `
    z-10
    absolute
    -top-8
    lg:-top-16
    bg-slate-800
    w-full
    px-3
    py-8
    box-border
    rounded-lg

    grid
    grid-cols-1
    md:grid-cols-2
    lg:grid-cols-3
    gap-y-10
`;

export const serviceItem = `
    w-full
    flex
    flex-col
    items-center
    justify-center
`;

export const serviceImgContainer = `
    w-16
    h-16
    rounded-md
    bg-pulse-yellow
    p-3
    box-border
`;

export const serviceHeader = `
    font-space
    text-xl
    font-bold
    text-white
    mt-4
`;
