import React from 'react';
import { section } from '../styles/how-it-works.styles';
import {
  heading,
  list_item,
  list_wrapper,
  matirc_header,
  matirc_wrapper,
  matric_description,
  wrapper,
} from '../styles/matrics.styles';

const MatricsSection = () => {
  return (
    <section className={section + 'mt-5 lg:mt-10'}>
      <div className={wrapper}>
        <div className="flex-1 px-3 box-border">
          <h2 className={heading}>Pulse Engine</h2>
          <ul className={list_wrapper}>
            <li className={list_item}>Lorem, ipsum dolor.</li>
            <li className={list_item}>Lorem ipsum dolor sit amet.</li>
            <li className={list_item}>Lorem ipsum dolor sit.</li>
          </ul>
          <div className={matirc_wrapper}>
            <h3 className={matirc_header}>99%</h3>
            <p className={matric_description}>Uptime Over Last Year.</p>
          </div>
          <div className={matirc_wrapper}>
            <h3 className={matirc_header}>99%</h3>
            <p className={matric_description}>Uptime Over Last Year.</p>
          </div>
        </div>
        <div className="flex-1 px-3 box-border">
          <div className={matirc_wrapper}>
            <h3 className={matirc_header}>99%</h3>
            <p className={matric_description}>Uptime Over Last Year.</p>
          </div>
          <div className={matirc_wrapper}>
            <h3 className={matirc_header}>99%</h3>
            <p className={matric_description}>Uptime Over Last Year.</p>
          </div>
          <div className={matirc_wrapper}>
            <h3 className={matirc_header}>99%</h3>
            <p className={matric_description}>Uptime Over Last Year.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MatricsSection;
