export const wrapper = `
    container
    h-full

    flex
    flex-col
    lg:flex-row

    items-center
    lg:items-start
    justify-center
    lg:justify-start
    mx-auto
`;

export const heading = `
    font-space
    text-4xl
    font-bold
`;

export const list_wrapper = `
    my-5
    list-disc
    ml-5
`;

export const list_item = `
    font-space
    font-bold
    text-md
    text-slate-600
`;

export const matirc_wrapper = `
    w-full
    
    py-5
    px-10
    border-box

    mb-5
    bg-slate-100
    hover:bg-slate-200
    
    ease-in-out
    duration-300
`;

export const matirc_header = `
    text-6xl
    font-space
    font-bold
    text-slate-800
    mb-3
`;

export const matric_description = `
    text-md
    font-medium
    font-space
`;
