import React from 'react';
import { heading } from '../styles/how-it-works.styles';
import {
  section,
  testimonial_company,
  testimonial_header,
  testimonial_item,
  testimonial_person,
  wrapper,
} from '../styles/testimonial.styles';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';

const Testimonial = () => {
  const TESTIMONIALS = [
    {
      clientName: 'Abir Rahman',
      company: 'Reckitt',
      role: 'Project Manager',
      saying:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos quasi facere aperiam! Doloribus.',
    },
    {
      clientName: 'Abir Rahman',
      company: 'Reckitt',
      role: 'Project Manager',
      saying:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos quasi facere aperiam! Doloribus.',
    },
    {
      clientName: 'Abir Rahman',
      company: 'Reckitt',
      role: 'Project Manager',
      saying:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos quasi facere aperiam! Doloribus.',
    },
    {
      clientName: 'Abir Rahman',
      company: 'Reckitt',
      role: 'Project Manager',
      saying:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos quasi facere aperiam! Doloribus.',
    },
  ];
  const sliderSettings = {
    lazyload: true,
    nav: false,
    mouseDrag: true,
    controls: false,
    items: 1,
    loop: true,
    startIndex: 0,
    animateDelay: 300,
    autoplay: true,
    responsive: {
      430: {
        items: 2,
      },
      770: {
        items: 3,
      },
    },
  };

  return (
    <div className={section}>
      <div className={wrapper}>
        <h2 className={heading}>Testimonials</h2>
        <div className="w-full">
          <TinySlider settings={sliderSettings}>
            {TESTIMONIALS.map((item) => (
              <div key={item.clientName} className={testimonial_item}>
                <h3 className={testimonial_header}>&ldquo; {item.saying} &rdquo;</h3>
                <p className={testimonial_person}>
                  {item.clientName}, {item.role}
                </p>
                <p className={testimonial_company}>{item.company}</p>
              </div>
            ))}
          </TinySlider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
