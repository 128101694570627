import React from 'react';
import { heading } from '../styles/hero-section.styles';
import {
  section,
  steps_wrapper,
  step_count,
  step_description,
  step_header,
  step_item,
  wrapper,
} from '../styles/how-it-works.styles';

const HowItWorks = () => {
  const STEPS = [
    {
      name: 'Upload Your Product Catalouge',
      description: 'Lorem ipsum dolor sit amet.',
    },
    {
      name: 'Schedule & Track Your Products Via Pulse Engine',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.Pariatur, quod.',
    },
    {
      name: 'View & Download Your Customized Reports and Insights',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing.',
    },
  ];
  return (
    <section className={section} id="howItWorks">
      <div className={wrapper}>
        <h1 className={heading}>How It Works</h1>
        <div className={steps_wrapper}>
          {STEPS.map((item, index) => (
            <div key={item.name} className={step_item}>
              <div className={step_count}>{index + 1}</div>
              <h2 className={step_header}>{item.name}</h2>
              <p className={step_description}>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
