import React from 'react';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import Navbar from './components/Navbar';
import {
  alertsBreakdownPoints,
  campaignBreakdownPoints,
  competitorBreakdownPoints,
  complianceBreakdownPoints,
  productBreakdownPoints,
  stockBreakdownPoints,
} from './consts/serviceBreakdownPoints';
import HeroSection from './sections/HeroSection';
import ServiceBreakDown from './sections/ServiceBreakDown';
import ServiceSection from './sections/ServiceSection';
import ProductServiceImg from './assets/product.svg';
import StockServiceImg from './assets/stock.svg';
import ComplianceServiceImg from './assets/compliance.svg';
import CompetitorServiceImg from './assets/competitor.svg';
import CampaignServiceImg from './assets/campaign.svg';
import AlertsServiceImg from './assets/alerts.svg';
import HowItWorks from './sections/HowItWorks';
import MatricsSection from './sections/MatricsSection';
import Testimonial from './sections/Testimonial';
import PricingSection from './sections/PricingSection';
import ContactSection from './sections/ContactSection';
import Footer from './components/Footer';

import 'react-toastify/dist/ReactToastify.css';

const Root = () => {
  const [serviceSectionHeight, setServiceSectionHeight] = useState();

  return (
    <div>
      <Navbar />
      <div>
        <HeroSection />
        <ServiceSection setHeight={setServiceSectionHeight} />
      </div>
      <div className="container mx-auto" style={{ marginTop: `${serviceSectionHeight - 20}px` }}>
        <h2 className="font-space font-bold text-4xl my-5 text-center">Our Solutions</h2>
      </div>
      <ServiceBreakDown
        title="Product Tracking"
        points={productBreakdownPoints}
        onRight={true}
        img={ProductServiceImg}
      />
      <ServiceBreakDown
        title="Stock Tracking"
        points={stockBreakdownPoints}
        img={StockServiceImg}
      />
      <ServiceBreakDown
        title="Competitor Analysis"
        points={competitorBreakdownPoints}
        onRight={true}
        img={CompetitorServiceImg}
      />
      <ServiceBreakDown
        title="Compliance Tracking"
        points={complianceBreakdownPoints}
        img={ComplianceServiceImg}
      />
      <ServiceBreakDown
        title="Campaign & Design"
        points={campaignBreakdownPoints}
        onRight={true}
        img={CampaignServiceImg}
      />
      <ServiceBreakDown
        title="Automated Alerts"
        points={alertsBreakdownPoints}
        img={AlertsServiceImg}
      />
      <HowItWorks />
      <MatricsSection />
      <Testimonial />
      <PricingSection />
      <ContactSection />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Root;
