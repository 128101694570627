import React from 'react';
import { section, wrapper } from '../styles/footer.styles';
import { brand } from '../styles/navbar.styles';

const Footer = () => {
  return (
    <footer className={section}>
      <div className={wrapper}>
        <div>
          <a href="#" className={brand}>
            Pulse.
          </a>
          <p className="text-white w-full lg:w-56 mt-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit tempore debitis
            accusantium.
          </p>
          <p className="text-sm mt-5 text-white">&copy; Manush Technologies LTD. 2022</p>
        </div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </footer>
  );
};

export default Footer;
