export const section = `
    w-full
    mt-5
    bg-pulse-black
    
`;

export const wrapper = `
    container
    mx-auto

    px-3
    py-10
    box-border

    grid
    grid-cols-1
    md:grid-cols-2
    lg:grid-cols-4
`;
