export const section = `
    w-full
    mt-5
    
    bg-pulse-black
`;

export const wrapper = `
    container
    mx-auto
    p-3
    box-border
`;

export const heading = `
    my-3
    text-2xl
    lg:text-4xl
    font-space
    font-bold
    text-white
    text-center
`;

export const description = `
    my-3
    text-md
    font-space
    font-bold
    text-slate-200
    text-center
`;

export const price_table_wrapper = `
    w-full  
    grid
    grid-cols-1
    lg:grid-cols-3
    gap-3

    mt-10
    mb-10
`;

export const price_table_item = `
    w-full
    rounded-md
    p-3
    box-border
`;

export const price_title = `
    text-lg
    font-bold
    font-space
`;

export const price_description = `
    text-sm
    font-medium
    font-space
    my-3
`;
