export const section = `
    w-full
    h-auto
    bg-white
`;
export const wrapper = `
    container
    flex
    flex-col
    items-center
    justify-center
    mx-auto
    box-border
    py-3
`;

export const heading = `
    font-bold
    font-space
    text-6xl
`;

export const testimonial_item = `
    my-5
    
    px-6
    lg:px-10

    py-8
    lg:py-10

    bg-pulse-orange
    rounded-md
    
    mx-0
    md:mx-3
    lg:mx-5
`;

export const testimonial_header = `
    font-space 
    font-bold
    text-2xl
    text-white
    mb-3
`;

export const testimonial_person = `
    text-sm
    font-bold
    font-space
    text-white
`;

export const testimonial_company = `
    text-md
    font-bold
    font-space
    text-white
`;
