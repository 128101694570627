import React, { useState } from 'react';
import { form_wrapper, heading, input, submit, textarea, wrapper } from '../styles/contact.style';
import emailjs from '@emailjs/browser';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactFormSchema } from '../validations/contact-form';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const ContactSection = () => {
  const resolver = yupResolver(contactFormSchema);
  const toastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver, mode: 'onChange' });
  const [isLoading, setIsloading] = useState(false);

  const sendMail = async (data) => {
    try {
      setIsloading(true);
      const { from_name, message, phone, companyName, name } = data;

      const mailBody = `
            From ${name}, ${companyName} \n
            \n
            ${message}
            \n
            Email back @ ${from_name}
            phone ${phone}

      `;
      await emailjs.send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_MAIL_TEMPLATE_ID,
        {
          to_name: from_name,
          from_name: from_name,
          message: mailBody,
        },
        process.env.REACT_APP_EMAIL_USER_ID,
      );

      reset({
        from_name: '',
        message: '',
        phone: '',
        companyName: '',
        name: '',
      });
      toast('🧐 Our sales team has been notified and will get in touch shortly', toastOptions);
      setIsloading(false);
    } catch (error) {
      console.error(error);
      toast('💀 failed to conatct', toastOptions);
    }
  };

  return (
    <section className="w-full mt-5" id="contact">
      <div className={wrapper}>
        <div className="flex-1 flex flex-col items-center lg:items-end p-4 box-border justify-start">
          <h1 className={heading}>Contact Sales</h1>
          <p className="w-full lg:w-72 text-center lg:text-right mt-5">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nesciunt distinctio eum, odit
            tempore nihil impedit?
          </p>
        </div>
        <div className="flex-1 flex flex-col items-center lg:items-start p-4 box-border justify-start">
          <div className={form_wrapper}>
            <label htmlFor="">Full name</label>
            <input type="text" className={input} placeholder="i.e Jhon Doe" {...register('name')} />
            {errors?.name && (
              <p className="text-sm font-light text-red-500">{errors?.name?.message}</p>
            )}
            <label htmlFor="">Company Name</label>
            <input
              type="text"
              placeholder="i.e Reckitt"
              className={input}
              {...register('companyName')}
            />
            {errors?.companyName && (
              <p className="text-sm font-light text-red-500">{errors?.companyName?.message}</p>
            )}
            <label htmlFor="">Work Email</label>
            <input
              type="email"
              placeholder="i.e abc@pulse.tech"
              className={input}
              {...register('from_name')}
            />
            {errors?.from_name && (
              <p className="text-sm font-light text-red-500">{errors?.from_name?.message}</p>
            )}
            <label htmlFor="">Phone</label>
            <input
              type="text"
              placeholder="Contact phone number"
              className={input}
              {...register('phone')}
            />
            {errors?.phone && (
              <p className="text-sm font-light text-red-500">{errors?.phone?.message}</p>
            )}
            <textarea
              placeholder="How can we help?"
              className={textarea}
              {...register('message')}
            />
            {errors?.message && (
              <p className="text-sm font-light text-red-500">{errors?.message?.message}</p>
            )}
            <button className={submit} onClick={handleSubmit(sendMail)}>
              {isLoading ? (
                <div className="w-full flex flex-row items-center justify-center">
                  <svg
                    role="status"
                    className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : (
                'Pulse It'
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
