export const wrapper = `
    container
    mx-auto
    p-3 
    box-border
    bg-pulse-yellow
    rounded-md

    flex
    flex-col
    lg:flex-row
    items-start
    justify-start
`;

export const heading = `
    text-2xl
    lg:text-4xl
    
    font-bold
    font-space
`;

export const form_wrapper = `
    w-full
    lg:w-72
`;

export const input = `
    w-full
    px-2
    py-3
    box-border
    mb-2
    rounded-md
`;

export const submit = `
    w-full
    px-2
    py-3
    box-border
    rounded-md
    bg-pulse-black
    hover:bg-pulse-pink

    text-white
    text-md
    font-bold
    font-space

    ease-in-out
    duration-300
`;

export const textarea = `
    w-full
    px-2
    py-3
    box-border
    mb-2
    rounded-md
`;
